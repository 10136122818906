<template>
  <base-form-item
      :field="field"
      :schemaVersion="schemaVersion"
  >
    <vue-editor
        :class="meta && meta.class ? meta.class : 'editor'"
        :customModules="customModulesForEditor"
        :editorOptions="editorSettings"
        :value="value"
        name="testEditor"
        style="display: inline-block; width: 100%; border-radius: 5px"
        type="text"
        v-model="value"
    >
    </vue-editor>
    <small
        class="help-block"
        v-if="backend_errors[field.dbName] && !checkinputRequired(field.dbName)"
    >
      <span v-html="validationTranslate(backend_errors[field.dbName][0])"></span>
    </small>
  </base-form-item>
</template>

<script>
import {VueEditor} from 'vue2-editor';
import {ImageDrop} from 'quill-image-drop-module';
import BaseFormItem from '../../UI/FormItem.vue';
import abstractForm from '../../mixin/index';

export default {
  components: {
    VueEditor, BaseFormItem,
  },
  mixins: [abstractForm],
  name: 'CreateOrUpdate',
  data() {
    return {
      customModulesForEditor: [
        {alias: 'imageDrop', module: ImageDrop},
      ],
      editorSettings: {
        modules: {
          imageDrop: true,
        },
      },
    };
  },
  methods: {},
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
>
.editor {
  .ql-toolbar {
    border: 1px solid #DCDFE6 !important;
    border-radius: 4px 4px 0 0;
  }

  .ql-container {
    border: 1px solid #DCDFE6 !important;
    border-radius: 0 0 4px 4px;
  }
}
</style>
